<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card :color="dark" class="">
      <v-container>
        <v-card-title class="">
          <h1 class="">Writer Profile</h1>
          <v-spacer></v-spacer>
          <!-- <v-btn color="red" large v-if="$route.name === 'allWriters' || $route.name === 'MyWriters' ">Block</v-btn>
          <v-btn color="success" large v-if="$route.name === 'blocked'">
            <v-icon class="mr-2">
              mdi-headphones
            </v-icon>
            Unblock
          </v-btn> -->
          <v-divider class="mx-5" vertical></v-divider>
          <v-btn
            icon
            tile
            text
            outlined
            :color="modalColor"
            @click="destroy()"
            class="white--text rounded"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
      
        <ProfileComponent :id="detail.id" v-if="detail" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "UploadModal",
  components: {
    ProfileComponent: () => import('@/modules/Profile/writerProfile/components/profileComponent'),
  },
  props: ["show", 'detail'],
  mixins: [colorMxn],
  data() {
    return {
      dialogue: false,
      after: true,
      uploaded: true,
    };
  },
  methods: {
    destroy() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.word-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
